@import "~antd/dist/antd.less";

// fonts
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,400;1,700&display=swap");
@import "./vars.less";

h1,
h2,
h3,
h4,
h5,
p,
small,
span,
a {
  font-family: "Lato", sans-serif;
  margin-bottom: 0px;
}

.page-content {
  padding: 20px;
  min-height: 80vh;
}

/**
12/6/2022 daniel.kwok
Global background color
*/
.ant-layout {
  background-color: #e0eef2;
}

/*
16/4/2022 daniel.kwok
remove left padding of PageHeader component
else will be mistallied with page-content
*/
.site-page-header,
.ant-page-header {
  padding-left: 0px;
  padding-right: 0px;
}

.hoverable:hover {
  color: #89a5ad;
}

/*23/8/2022 daniel.kwok
https://ant.design/components/layout/
*/
#components-layout-demo-top-side-2 .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}
.ant-row-rtl #components-layout-demo-top-side-2 .logo {
  float: right;
  margin: 16px 0 16px 24px;
}
.site-layout-background {
  background: #fff;
}

@primary-color: #c01172;